<template>
  <div v-show="showing">
    <div class="page-header">
      <div class="container">
            <h1 class="title-pre">
                <vue-typer :repeat='0' v-bind:text="'Quiz \n '+datadb.name" :type-delay='80'></vue-typer>
            </h1>
            <div class="text-left">
                <h3>Nama Lengkap : <b>{{datausers.name}}</b> / {{datausers.username}}<br>
                Aturan Test Online :</h3>
                <ul class="list-aturan">
                    <li>Test Akan Berjalan Sesuai dengan waktu yang sudah ditentukan yaitu <br><b>( {{datadb.duration+' Menit'}} )</b></li>
                    <li>Test Hanya dapat dilakukan 1 kali saja, jadi lakukan yang terbaik saat mengerjakan</li>
                    <li>Setelah Selesai menjawab semua pertanyaan, silahkan kirim jawaban anda dengan klik tombol <b>SUBMIT QUIZ</b> yang berada dibagian bawah answer sheet</li>
                    <li>Waktu akan dimulai saat setelah anda klik <b>MULAI TEST</b></li>
                </ul>
                <div class="quiz-intructions" v-html="datadb.instruction"></div>
            </div>
        <div class="section-bottom">
            <button class="btn btn-neutral btn-lg btn-round btn-block btn-start" v-on:click="doquiz">MULAI TEST</button>
        </div>
       
        
      </div>
    </div>
  </div>
</template>
<script>
import { VueTyper } from 'vue-typer'
export default {
  name: 'prequiz',
  bodyClass: 'index-page',
  components:{
      VueTyper
  },
  data() {
      return {
          showing:false,
          datadb: [],
          datausers:null,
          quizwaiting:0,
          dataform:{
              username:this.$userdetInfo.username,
              quiz:null,
              slug:this.$route.params.slug
          }
      };
  },
  beforeMount() {
      this.getData()
      this.checkfirst()
  },
  mounted() {
      this.getdatauser()
  },
  methods: {
    async checkfirst() {
        await this.$http.get(`/quiz/`+this.$route.params.slug).catch(err => {
          console.log(err)
        })
          this.$http({
                url: `/quiz/check-start`,
                method: "POST",
                data:this.dataform
            }).then(res => {
                if(res.status === 200 ){
                    this.$router.push({
                        name: 'quiz', 
                        params: { slug: this.$route.params.slug }
                    });
                }else if(res.status === 205){
                    console.log('306 direct')
                    window.close()
                }else if(res.status === 204){
                    this.showing=true
                }
            },(error) => {
                this.showing=true
            });   
      },

    getData() {
        this.$http({
            url: `/quiz/`+this.$route.params.slug,
            method: "GET"
        }).then(
            res => {
                this.datadb = res.data.data
                this.dataform.quiz = this.datadb.id
                this.dataform.slug = this.$route.params.slug
            },
            () => {
                console.log('API Error')
            }
        );
    },
    getdatauser() {
      return this.$http.get('/auth/profile')
      .then(({ data }) => {
          this.datausers = data
      })
    },
    doquiz(){
        if(confirm("Waktu akan dimulai saat menyetujui pesan ini, dan tidak dapat diulang lagi.")){
            this.$http({
                url: `/quiz/start`,
                method: "POST",
                data:this.dataform
            }).then(res => {
                if(res.status === 200 ){
                    this.$router.push({
                        name: 'quiz', 
                        params: { slug: this.$route.params.slug }
                    });
                }else if(res.status === 504){
                    this.$router.push({ name: 'dashboard' });
                }
                console.log(res)
            },() => {
                console.log('API Errors')
            });            


        /*     this.$router.push({
                name: 'quiz', 
                params: { slug: this.$route.params.slug }
            }); */
            
        }
    }

  },
}
</script>
<style>
.vue-typer .custom.char.typed {
  color: #ffffff;
}
</style>
<style scoped>
.page-header{
  background: #232327;
}

.vue-typer .custom.char.typed {
    font-family: 'Montserrat', 'Helvetica Neue',  Arial, sans-serif;
    color: #fff !important;
}

.list-aturan{
    font-size: 21px;
    list-style-type:decimal;
}
.section-bottom{
    position: absolute;
    /* bottom: 40px; */
    right: 40%;
    margin: 0 auto;
}
.section-bottom>button{
    font-weight: bold;
}
.title-pre>*{
    font-weight: bold;
    color: #ffffff !important;
}

.quiz-intructions{
    font-size: 14px;
}

.quiz-intructions>p{
    font-size: 14px;
    font-weight: bold !important;
    font-size: 200%;
}

@media screen and (max-width: 991px){
    .quiz-intructions{
    font-size: 12px;
}
    .list-aturan{
        font-size: 14px;
        list-style-type:decimal;
    }
    .title-pre>*{
        font-size: 26px;
        line-height: 0.1;
        font-weight: bold;
    }
    .section-bottom{
        right: 35% !important;
        bottom: unset;
    }
    h3{
        font-size: 18px !important;
        margin-bottom: 30px !important;
        line-height: 1.4em !important; 
    }
    h1{
        font-size: 21px !important;
        margin-bottom: 30px !important;
        line-height: 1.4em !important; 
    }

}
@media screen and (max-width: 768px){
    .quiz-intructions{
    font-size: 12px;
}
    .list-aturan{
        font-size: 14px;
        list-style-type:decimal;
    }
    .title-pre>*{
        font-size: 26px;
        line-height: 0.1;
        font-weight: bold;
    }
    .section-bottom{
        right: 30% !important;
        bottom: unset;
    }
    h3{
        font-size: 18px !important;
        margin-bottom: 30px !important;
        line-height: 1.4em !important; 
    }
    h1{
        font-size: 21px !important;
        margin-bottom: 30px !important;
        line-height: 1.4em !important; 
    }
}

</style>
