import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/pages/Index.vue'
import Login from '@/pages/Login.vue'
import Profile from '@/pages/Profile.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Prequiz from '@/pages/Prequiz.vue'
import Quiz from '@/pages/Quiz.vue'
import MainNavbar from '@/layout/MainNavbar.vue'
import MainFooter from '@/layout/MainFooter.vue'
import NotFoundComponent from '@/layout/404.vue'

Vue.use(Router)

const routes = [
    {
      path: '/',
      name: 'home',
      components: { default: Index, header: MainNavbar},
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: {
        title: 'Home'
      }
    },
    {
      path: '/pre-quiz/:slug',
      name: 'prequiz',
      components: { default: Prequiz},
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: {
        auth: true,
        title: 'Pree Quiz'
      }
    },
    {
      path: '/quiz/:slug',
      name: 'quiz',
      components: { default: Quiz},
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: {
        auth: true,
        title: 'Quiz'
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 800 }
      },
      meta: {
        title: 'Sign In'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: {
        auth: true,
        title: 'Profile'
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      components: { default: Dashboard, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: {
        auth: true,
        title: 'Dashboard'
      }
    },

    {
      path: '*',
      component: NotFoundComponent,
      meta: {
          title: '404'
      }
  }
  ]

  const router = new Router({
    // mode: 'history',
    hashbang: false,
    base: process.env.BASE_URL,
    routes,
    linkExactActiveClass: 'active',
    scrollBehavior: to => {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
  
  router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
  
    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
      next('/login')
      return
    }
    next()
  })
  
    
export default router
