import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // isLogged:false,
    user: null,
    userdetail: null
  },

  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
      // this._vm.$userdetailInfo
    },

    clearUserData () {
      localStorage.removeItem('user')
      localStorage.removeItem('userdetail')
      location.reload()
    },

    setDetailData (state, userData) {
      state.userdetail = userData
      localStorage.setItem('userdetail', JSON.stringify(userData))
    }
  },

  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('/auth/login', credentials)
        .then(({ data }) => {
          commit('setUserData', data)
          this.dispatch('getdatauser')
        })
    },

    logout ({ commit }) {
      commit('clearUserData')
    },

    getdatauser({ commit }) {
      return axios.get('/auth/profile')
      .then(({ data }) => {
        commit('setDetailData', data)
      })
    }
  },

  getters: {
    isLogged: state => !!state.user
  }
})
