<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <!-- <zoom-x-transition :duration="800" :delay="-100"> -->
        <router-view />
      <!-- </zoom-x-transition> -->
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
import { ZoomXTransition, FadeTransition, CollapseTransition  } from 'vue2-transitions'
export default {
   components: {
    ZoomXTransition,
    FadeTransition,
    CollapseTransition
  },
  
  watch: {
      $route: {
            immediate: true,
            handler(to, from) {
                // document.title = "#nndproject | " + to.meta.title || "Monitoring Skripsi";
                document.title = this.$appName+" | " + to.meta.title || "Monitoring Skripsi";
            },
      },
  },

}
</script>
