<template>
  <div class="page-header clear-filter" filter-color="black">
    <div class="page-header-image"  style="background-image: url('img/bg-home.jpg')"></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">  
          <form @submit.prevent="login" required>
          <card type="login" plain>
            <div slot="header" class="logo-container">
              <img v-lazy="'img/now-logo.png'" alt="" />
            </div>
            <fg-input
              name="username"
              class="no-border input-lg mb-3"
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="username"
              v-model="username" required>
            </fg-input>

            <fg-input
              type="password"
              name="password"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons objects_key-25"
              placeholder="Password"
              v-model="password" required>
            </fg-input>
           
            <template slot="raw-content">
              <div class="card-footer text-center">
                <button type="submit" class="btn btn-block btn-round btn-primary btn-lg"><i class="now-ui-icons ui-1_lock-circle-open mr-1"></i>Sign In</button>
              </div>
            </template>
            
          </card>
          </form>
        </div>
      </div>
    </div>
     <alert type="danger" dismissible v-if="notifAlert">
      <div class="alert-icon">
        <i class="now-ui-icons ui-1_bell-53"></i>
      </div>
      <strong>Oh snap!</strong> Sign In Error please check your username/password.
    </alert>
    <main-footer></main-footer>
   
  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Alert } from '@/components'
import MainFooter from '@/layout/MainFooter'
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Alert
  },
  data () {
      return {
      username: '',
      password: '',
      notifAlert: false
    }
  },

  methods: {
    login () {
      this.notifAlert = false
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
        .catch(err => {
          console.log(err)
          this.notifAlert = true
        })
    }
  }
}
</script>
<style scoped>
*{
    font-size: 100% !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  *{
      font-size: 98% !important;
  }
}

</style>
