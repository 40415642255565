<template>
  <div>
    <div class="page-header clear-filter" filter-color="black">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg-home.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="img/now-logo.png" alt="" />
          <h1 class="h1-seo">Jobs Placement Center</h1>
          <h3>PT. ARION INDONESIA</h3>
        </div>
        <h6 class="category category-absolute">
          karir.arionindonesia.co.id | mglab.co.id | tokovideowall.com
        </h6>
      </div>
      <main-footer></main-footer>
    </div>
  </div>
</template>
<script>
import Navigation from './components/Navigation'
import MainFooter from '@/layout/MainFooter'



export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    MainFooter,
    Navigation,
  }
}
</script>
<style scoped>

.category-absolute{
  color: #fff !important;
  text-transform: lowercase !important;
}

h1{
  font-size: 2.5rem !important;
}
</style>
