<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">{{$appName}}</router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item" v-if="isLogged">
        <router-link class="nav-link" to="/dashboard">
        <i class="now-ui-icons tech_tv"></i>
          <p>Dashboard</p>
      </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          href="http://arionindonesia.co.id"
          target="_blank">
          <i class="now-ui-icons shopping_delivery-fast"></i>
          <p>PT. Arion Indonesia</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          href="http://karir.arionindonesia.co.id"
          target="_blank">
          <i class="now-ui-icons education_paper"></i>
          <p>Karir</p>
        </a>
      </li>
      <li class="nav-item" v-if="isLogged">
        <button @click="logout()" class="nav-link btn btn-neutral" to="/login">
        <i class="far fa-user mr-2"></i>
          <p>LOGOUT</p>
      </button>
        
      </li>
      <li class="nav-item" v-else>
        <router-link class="nav-link btn btn-neutral" to="/login">
        <i class="far fa-user mr-2"></i>
          <p>LOGIN</p>
      </router-link>
      </li>

      <!-- <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/CreativeTimOfficial"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li> -->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components'
import { Popover } from 'element-ui'
import { mapGetters } from 'vuex'
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  },
  computed: {
    ...mapGetters([
      'isLogged'
    ])
  },

  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }


}
</script>

<style scoped>
.nav-link.active {
    padding:0 10px 0 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.1875rem;
}

.navbar {
  font-size: 17px;
}
</style>
