<template>
    <div>
    <div v-for="(item, soalnomor) in dbdatas.soal" v-bind:key="item.id">
        <div class="soal">
            <span class="number">{{++soalnomor}}</span>
            <div class="question" v-html="item.question"></div>
        </div>
        <div class="answer-line" style="padding-top:-30px;" @click="$emit('event',soalnomor,'goto')">
            <i class="text-success font-weight-bold" v-if="item.employee_answer && item.employee_answer !=''" >Answered</i>
            <!-- v-html="item.employee_answer" -->
            <i class="text-danger" v-else>No Answered</i>
        </div>
    </div>
    <div class="pull-right mr-5">
        <button class="btn btn-round btn-block btn-lg btn-sending" @click="$emit('submit')">Submit Quiz</button>
    </div>
    </div>
</template>
<script>
import Button from '../components/Button.vue';
export default {
  components: { Button },
    props:['slug'],
    data () {
        return {
            dbdatas:[]
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$http({
                url: `/quiz/quiz-summary/`+this.slug,
                method: "get"
            }).then(
                res => {
                    this.dbdatas = res.data.data
                },
                () => {
                    console.log('API Error')
                }
            );
        },

        sendfullquiz() {
            this.$http({
                url: `/quiz/send-full-answer`,
                method: "POST",
                data:{slug:this.slug}
            }).then(
                res => {
                    this.$router.push({ name: 'dashboard' })
                },
                () => {
                    console.log('API Error')
                }
            );
        }

    },


}
</script>
<style>
.btn-sending{
    background-color: #f1103a !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
}
.page-header{
  background: #fafafa;
}
nav{
    max-height: 150px;
}
.header{
    padding: 25px 35px 10px 10px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,0.05);
}
.content-soal{
    padding-top: 20px;
}
.ps{
    max-height: var(--height);
}
.ps__thumb-y {
    color: #f1103a;
}

.page-item .page-link{
  border-radius: 50px !important;
  transition: all .3s;
  margin: 0 3px;
  min-width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.pagination.pagination-info .page-item.active > .page-link, .pagination.pagination-info .page-item.active > .page-link:focus, .pagination.pagination-info .page-item.active > .page-link:hover {
  background-color: #f1103a;
  border-color: #f1103a; }
.soal{
    font-weight: 600;
    line-height: 70px;
    padding-top: 4px;
    padding-right: 20px;
    padding-left: 3px;
}

.timer{
    font-size: 20px;
    font-weight: 500;
}
.timer-cd{
    color: #f1103a;
    font-weight: bold;
}

.soal>.number{    
    color: #f1103a;
    font-size: 2.5em;
    float: left;
    line-height: 40px;
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 3px;
    box-shadow: 0 4px 0 rgba(255, 17, 17, 1);
}
.soal>p, .soal>.question{
    font-family: 'Poppins', 'Helvetica Neue',  Arial, sans-serif;
    font-size: 1.2em;
    font-weight: 500 !important;
    padding: 5px 3%;
    text-align: justify;
}

.answer-line {
    font-family: 'Poppins', 'Helvetica Neue',  Arial, sans-serif;
    font-weight: 400;
    margin-left: 5%;
    float: left;
    width: 100%;
    padding: 5px 1px;
    font-size: 1.2em;
    cursor: pointer;
}

.ckeditor-place{
  max-width: 90%;
  min-width: 80%;
}


@media screen and (max-width: 991px){
*{
    font-size: 85%;
}

.ckeditor-place{
  max-width: 200%;
  margin-left:-20% ;
}

.timer, .page-quiz{
    text-align: center;
}

/* .ps{
    max-height: 600px;
} */
}
@media screen and (max-width: 1300px){
    *{
        font-size: 90%;
    }

    .timer, .page-quiz{
        text-align: center;
    }

}

</style>