<template>
<div>
      <div class="container-fluid" v-if="showing">
          <nav class="row header">
                <div class="col-sm-12 col-md mr-auto page-quiz">
                <ul class="pagination pagination-info">
                  <li class="page-item prev-page" @click="movequestion(pagination.current_page,'prev')" :disabled="!pagination.prev_page_url">
                    <a aria-label="Previous" class="page-link"><i class="now-ui-icons arrows-1_minimal-left"></i> prev</a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link">{{pagination.current_page}} / {{pagination.last_page+1}}</a>
                  </li>
                  <li class="page-item page-pre next-page" @click="movequestion(pagination.current_page,'next')" :disabled="!pagination.next_page_url">
                    <a aria-label="Next" class="page-link">next <i class="now-ui-icons arrows-1_minimal-right"></i></a>
                  </li>
                </ul>
                </div>
                <div class="col-md answersheet">
                  <a class="btn btn-round btn btn-simple btn-danger" @click="fullanswer = !fullanswer" >Answer Sheet</a>
                </div>
                <div class="col-md timer">
                    <vue-countdown-timer
                        @start_callback="startCallBack('event started')"
                        @end_callback="endCallBack('event ended')"
                        :start-time="'2020-10-10 00:00:00'"
                        :end-time="endAt"
                        :interval="1000"
                        :start-label="'Until start:'"
                        :end-label="'Sisa Waktu :'"
                        label-position="begin"
                        :end-text="'ended!'"
                        :hour-txt="'jam'"
                        :minutes-txt="'menit'"
                        :seconds-txt="'detik'">
                        <template slot="countdown" slot-scope="scope">
                            <!-- <span class="timer-cd">{{scope.props.hours}}</span> {{scope.props.hourTxt}}
                            <span class="timer-cd">{{scope.props.minutes}}</span> {{scope.props.minutesTxt}}
                            <span class="timer-cd">{{scope.props.seconds}}</span> {{scope.props.secondsTxt}} -->
                            <span class="timer-cd">{{scope.props.hours}}</span> :
                            <span class="timer-cd">{{scope.props.minutes}}</span> :
                            <span class="timer-cd">{{scope.props.seconds}}</span>
                        </template>
                    </vue-countdown-timer>
                </div>
          </nav>
        <perfect-scrollbar :options="optscroll" :style="cssVars">
          <template v-if="!fullanswer">
            <div class="content-soal" v-for="(item) in dbdata.soal.data" v-bind:key="item.id">
                <div class="soal">
                  
                    <span class="number">{{pagination.current_page}}.</span>
                    <div v-if="item.attactment">
                    <!-- <img class="img-question" v-bind:src="'http://localhost:8000/uploads/img-questions/' + item.attactment"> -->
                    <img class="img-question" v-bind:src="item.attactment | imagesource">
                  </div>
                    <div class="question" v-html="item.question"></div>
                    
                </div>
                <div class="answer-line" v-if="item.type == 'multiple'">
                  <div v-for="subitem in  JSON.parse(item.options)" v-bind:key="subitem">
                    <n-checkbox v-bind:value="subitem" v-model="dataform.answer" v-if="item.answer_type =='checkbox'">{{subitem}}</n-checkbox>
                    <n-radio v-bind:label="subitem" v-model="dataform.answer" v-else>{{subitem}}</n-radio>
                  </div>
                </div>
                <div class="answer-line" v-else-if="item.type == 'tf'">
                    <n-radio v-model="dataform.answer" label="True">True</n-radio>
                    <n-radio v-model="dataform.answer" label="False">False</n-radio>
                </div>
                <div class="answer-line essay" v-else-if="item.type == 'essay'">
                  <div class="ckeditor-place">
                    <ckeditor v-model="dataform.answer" :config="editorConfig"></ckeditor>
                  </div>
                </div>
            </div>
          </template>
          <fullanswer v-if="fullanswer" :slug="$route.params.slug" @event="movequestion" @submit="submitquiz"></fullanswer>
        </perfect-scrollbar>
      </div>





</div>
</template>
<script>
import Vue from 'vue'
import CKEditor from 'ckeditor4-vue'
import Fullanswer from '@/pages/Fullanswer'
Vue.use(CKEditor)
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import {
  Radio,
  Checkbox,
  Pagination
} from '@/components'

import TimerCountDown from './components/Timer';

export default {
  name: 'quiz',
//   bodyClass: 'index-page',

  components: {
    PerfectScrollbar,
    [Checkbox.name]: Checkbox,
    [Pagination.name]: Pagination,
    [Radio.name]: Radio,
    TimerCountDown,
    Fullanswer
  },
  data () {
    return {
      fullanswer:false,
      showing:true,
      dbdata:[],
      dataform:{
        quiz_id:null,
        question_id:null,
        answer:[]
      },
      pagination: {
        simple: 1,
      },
      optscroll:{
        minScrollbarLength: 100,
        watchOptions:true,
        useBothWheelAxes: false,
        suppressScrollX: true
      },
      editorConfig: {
        //  toolbarLocation: 'bottom',
        uiColor: '#ffffff',
        contentsCss: ["body {color:#2c2c2c; font-weight:normal; font-size:100%; font-family: 'Poppins', 'Helvetica Neue',  Arial, sans-serif;} "],
        toolbarGroups: [{
          "name": "basicstyles",
          "groups": ["basicstyles"]
        },
        {
          "name": 'clipboard',
          "groups": ['PasteFromWord', '-', 'Undo', 'Redo']
        },
        {
          "name": "links",
          "groups": ["links"]
        },
        {
          "name": "paragraph",
          "groups": ["list", "blocks"]
        },
        {
          "name": "document",
          "groups": ["mode"]
        },
        
        {
          "name": "styles",
          "groups": ["styles"]
        },
        {
          "name": "about",
          "groups": ["about"]
        }
      ],
      },
      windowHeight: window.innerHeight,
      endAt:  (new Date).getTime()
    }
  },
  watch(){
      this.cssVars()
  },
  mounted() {
      this.getdata()
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  computed:{
    cssVars() {
      return {
        '--height': (this.windowHeight -150) + 'px'
      }
    }
      
  },
  filters:{
      imagesource(value){
        return process.env.VUE_APP_BASE_IMG+'/uploads/img-questions/'+value
      }
  },
  methods: {
    movequestion(url,arrow){
      if(arrow =='next'){
        if(this.pagination.current_page > this.pagination.last_page){
          this.fullanswer=true
        }else if(this.pagination.last_page == url){
          this.fullanswer=true
          this.pagination.current_page+=1
          this.pagination.next_page_url=this.pagination.last_page_url
          this.getdata(this.pagination.next_page_url, (url+1))

        }else{
          this.fullanswer=false
          this.getdata(this.pagination.next_page_url, url)
        }

       

      }else if(arrow == 'prev'){
        if((this.pagination.last_page+1) == url && this.fullanswer==true ){
          this.fullanswer=false
          this.pagination.current_page-=1
          this.pagination.prev_page_url=this.pagination.last_page_url

        }else{
          this.fullanswer=false
          this.getdata(this.pagination.prev_page_url, url)
        }

      }else if(arrow == 'goto'){
        this.fullanswer=false
        this.getdata(`/list-question/`+this.$route.params.slug+'?page='+url, url)
      }

        
    },
    getdata(page, nomor){
      this.sendAnswer()  
      page = page || `/list-question/`+this.$route.params.slug
      this.$http({
            url: page,
            method: "GET",
        }).then(
            res => {
                if(res.status == 200){
                  this.dbdata = res.data.data
                  this.dataform.question_id=this.dbdata.soal.data[0].id
                  this.dataform.quiz_id=this.dbdata.soal.data[0].quiz_id
                  if(this.dbdata.soal.data[0].answer_type =="checkbox"){
                    this.dataform.answer=JSON.parse(this.dbdata.soal.data[0].employee_answer)
                  }else{
                    this.dataform.answer=this.dbdata.soal.data[0].employee_answer
                  }
                  this.endAt= this.dbdata.limit
                  this.makePagination(this.dbdata.soal)
                  if(nomor == (this.pagination.last_page+1)){
                    this.pagination.current_page = nomor
                  }
                }else if(res.status == 205){
                  this.submitquiz()
                }
            }
        ).catch(function (error) {
            console.log('API Error')
        });
    },

    sendAnswer(){
      this.$http({
          url:'/quiz/send-answer',
          method:"POST",
          data:this.dataform
      }).then(res=>{
          console.log('answered')
      })
    },

    makePagination: function(data){
        let pagination = {
            current_page: data.current_page,
            last_page: data.last_page,
            next_page_url: data.next_page_url,
            prev_page_url: data.prev_page_url,
            last_page_url: data.last_page_url,
        }                
        this.pagination = pagination
    },
    
    startCallBack: function(x) {
      // console.log(x);
    },
    
    endCallBack: function(x) {
      // this.fullanswer=true
      this.submitquiz()
      // 
    },

    submitquiz(){
            this.$http({
                url: `/quiz/send-full-answer`,
                method: "POST",
                data:{slug:this.$route.params.slug}
            }).then(
                res => {
                    this.$router.push({ name: 'dashboard' })
                },
                () => {
                    console.log('API Error')
                }
            );
    },
    
    onResize() {
      this.windowHeight = window.innerHeight
      
    },
  },
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style scoped>
.page-header{
  background: #fafafa;
}
nav{
    max-height: 150px;
}
.header{
  
    padding: 25px 35px 10px 10px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,0.05);
}
.content-soal{
    padding-top: 20px;
}
.ps{
    /* width: 90%; */
    max-height: var(--height);
}
.ps__thumb-y {
    color: #f1103a;
}

.page-item .page-link{
  border-radius: 50px !important;
  transition: all .3s;
  margin: 0 3px;
  min-width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.pagination .page-item .page-link {
  background: #f1103a ;
  color: #fff;
  font-weight: 600;
}



.pagination.pagination-info .page-item.active > .page-link, .pagination.pagination-info .page-item.active > .page-link:focus, .pagination.pagination-info .page-item.active > .page-link:hover {
  background-color: #f1103a;
  border-color: #f1103a; }
.soal{
    font-weight: 600;
    line-height: 70px;
    padding-top: 4px;
    padding-right: 20px;
    padding-left: 3px;
}
.answersheet{
  text-align: center;
}
.timer{
    padding-top: 10px ;
    text-align: right;
    font-size: 20px;
    font-weight: 500;
}
.timer-cd{
    color: #f1103a;
    font-weight: bold;
}

.soal>.number{    
    color: #f1103a;
    font-size: 3em;
    float: left;
    line-height: 70px;
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 3px;
    box-shadow: 0 4px 0 rgba(255, 17, 17, 1);
}
.soal>p, .soal>.question{
    font-family: 'Poppins', 'Helvetica Neue',  Arial, sans-serif;
    font-size: 2em;
    font-weight: 500 !important;
    /* line-height: 70px; */
    padding: 5px 5%;
    text-align: justify;
}


.answer-line {
  font-family: 'Poppins', 'Helvetica Neue',  Arial, sans-serif;
  font-weight: 300;
	margin-left: 5%;
	float: left;
	width: 100%;
	padding: 20px 50px;
  font-size: 2em;
}

.ckeditor-place{
  max-width: 90%;
  min-width: 80%;
}


.img-question{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


@media screen and (max-width: 1600px) and (min-width: 1366px){
.answersheet{
  font-size: 1.5em;
  text-align: center;
}

.ckeditor-place{
  max-width: 200%;
  margin-left:-20% ;
}


.answer-line {
  font-size: 2em;
  padding-right: 8%;
}


.timer{
    font-size: 2em;
    margin-top: 5px;
    text-align: right;
}

}

@media screen and (max-width: 1280px) and (min-width: 768px){
.header{
    padding: 10px 0px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,0.05);
}

.pagination.pagination-info{
  margin-left: 10%;
}

.pagination .page-item .page-link {
  font-size: 10px;
  }

.answersheet{
  font-size: 150%;
  text-align: center;
}

.ckeditor-place{
  max-width: 200%;
  margin-left:-20% ;
}

.page-quiz{
    text-align: center;
}


.answer-line {
  font-size: 3em;
}

.essay{
  margin-left: 15%;
  padding-right: 20%;
}

.timer{
    font-size: 2em;
    margin-top: 5px;
    text-align: right;
}

}

@media only screen 
  and (min-device-width: 667px) 
  and (max-device-width: 736px) {

    .header{
        padding: 10px 0px;
        box-shadow: inset 0 -1px 0 rgba(0,0,0,0.05);
    }

    .pagination.pagination-info{
      margin-left: 0%;
      margin-bottom: -15%;
    }

    .pagination .page-item .page-link {
      padding: 0px 10px;
      margin: 0 3px;
      font-size: 10px;
      }

    .answersheet{
      font-size: 1.5em;
      text-align: center;
    }

    .ckeditor-place{
      max-width: 200%;
      margin-left:-20% ;
    }

    .page-quiz{
        text-align: center;
    }


    .answer-line {
      margin-left: -15px;
      font-size: 2.5em;
    }
    
        
    .essay{
      margin-left: 8%;
      padding-right: 15%;
    }

    .timer{
        font-size: 2em;
        margin-top: -50px;
        text-align: right;
    }

}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {

    .header{
        padding: 10px 0px;
        box-shadow: inset 0 -1px 0 rgba(0,0,0,0.05);
    }

    .pagination.pagination-info{
      margin-left: 15%;
    }

    .pagination .page-item .page-link {
      padding: 0px 20px;
      margin: 0 3px;
      font-size: 10px;
      }

    .answersheet{
      font-size: 1.5em;
      text-align: left;
    }

    .ckeditor-place{
      max-width: 200%;
      margin-left:-20% ;
    }

    .page-quiz{
        text-align: center;
    }


    .answer-line {
      margin-left: -15px;
      font-size: 2.5em;
    }

    .essay{
      margin-left: 8%;
    }

    .timer{
        font-size: 2em;
        margin-top: -50px;
        text-align: right;
    }

}

</style>