<template>
  <div class="section editprofile" data-background-color="black">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="text-center col-md-12 col-lg-8">
          <h3 class="title">Data Profile</h3>
        </div>
        <div class="text-center col-md-8 col-lg-6">
          <form @submit.prevent="sendform">
          <template>
            <fg-input
              class="no-border input-lg"
              placeholder="Username"
              addon-left-icon="now-ui-icons business_badge"
              v-model="dbdata.username"
              readonly>
            </fg-input>
            <fg-input
              class="no-border input-lg"
              placeholder="Full Name..."
              addon-left-icon="now-ui-icons users_circle-08"
              v-model="formdata.name">
            </fg-input>

            <fg-input
              class="no-border input-lg"
              type="email"
              placeholder="Email"
              addon-left-icon="now-ui-icons ui-1_email-85"
              v-model="formdata.email" required>
            </fg-input>
            <fg-input
              class="no-border input-lg"
              placeholder="Contact Person"
              addon-left-icon="now-ui-icons tech_mobile"
              v-model="formdata.cp">
            </fg-input>
            <fg-input
              class="no-border input-lg"
              type="password"
              placeholder="New Password"
              addon-left-icon="now-ui-icons objects_key-25"
              name="password"
              v-model="formdata.password">
            </fg-input>
            <fg-input
              class="no-border input-lg"
              type="password"
              placeholder="Confirm Password"
              addon-left-icon="now-ui-icons objects_key-25"
              name="password_confirmation"
              v-model="formdata.password_confirmation">
            </fg-input>
            <div class="pull-left text-left ml-2">
              <i>**isi password untuk mengubah password saat ini,
                <br/>**password minimal 6 huruf/angka</i>
            </div>
            <div class="clear-fix"></div>
          </template>
          <div class="card-footer text-center">
            <button type="submit" class="btn btn-round btn-neutral btn-lg"><i class="now-ui-icons ui-1_lock-circle-open mr-1"></i>Update Profile</button>
          </div>
          
          </form>
       
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="row text-center mt-5">
        <div class="col-md-8 ml-auto mr-auto">
          <h3>Informasi</h3>
          <h5 class="description">
            Pastikan data yang anda input benar, pihak <b class="text-warning weight-600">HRD</b> akan menghubungi anda
            melalui data yang di masukkan pada form diatas.
          </h5>
        </div>
      </div>
      <br />
      <br />
     
    </div>

    <div v-if="showalert">
      <alert type="danger" dismissible v-for="error in detailerror" v-bind:key="error.id">
        <div class="alert-icon">
          <i class="now-ui-icons ui-1_bell-53"></i>
        </div>
        <strong>Oh snap!</strong> {{error}}
      </alert>
    </div>
  </div>
</template>
<script>

import { Card, FormGroupInput, Button, Alert } from '@/components'
export default {
  name: 'profile-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Alert
  },
  data () {
      return {
      formdata: {
        name:null,
        email:null,
        cp:null,
        password:null,
        password_confirmation:null
      },
      dbdata:null,
      showalert: false,
      detailerror:null
    }
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData() {
        this.$http({
            url: `/profile-edit`,
            method: "GET",
        }).then(
            res => {
                this.dbdata = res.data.data;
                this.formdata.name = res.data.data.name;
                this.formdata.email = res.data.data.email;
                this.formdata.cp = res.data.data.has_detail.cp;
            },
            () => {
                console.log('API Error')
            }
        );
    },

    sendform(){
      this.showalert = false
      this.$http({
            url: `/profile-update`,
            method: "POST",
            data : this.formdata
        }).then(res => {
                alert('data success')
                
            })
          /*   .catch(function (error) {
                  this.showalert= true
                  this.detailerror = error.response.data.errors
                alert('data error')
                  //  console.log(error.response.data.errors)
                }); */
                .catch(err => {
                  this.showalert = true
                  this.detailerror = err.response.data.errors
        })

    }
    
  },
}
</script>
<style>
.editprofile{
  min-height: 1100px !important;
}

</style>
