<template>
    <transition>
         <div class="flex-center position-ref full-height animate__animated animate__bounceInUp">
            <div class="code">404</div>
            <div class="body">
                <div class="message">Not Found</div>
                <span style="padding-left: 10px;">nndproject</span>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    
}
</script>
<style>
.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.code {
    border-right: 2px solid;
    font-size: 36px;
    padding: 0 15px 0 15px;
    text-align: center;
}
.body{
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
}
.message {
    font-size: 26px;
}

.body > span{
    font-size: 14px;
    text-align: left;

}
</style>