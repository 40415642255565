import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import NowUiKit from './plugins/now-ui-kit'
import VueCountdownTimer from 'vuejs-countdown-timer'


Vue.config.productionTip = false
Vue.prototype.$appName = process.env.VUE_APP_NAME
Vue.prototype.$userdetInfo = JSON.parse(localStorage.getItem('userdetail'))
Vue.prototype.$http = axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(NowUiKit)
Vue.use(VueCountdownTimer)

new Vue({
  router,
  store,
  created () {
    const userInfo = localStorage.getItem('user')
    const userdetInfo = localStorage.getItem('userdetail')
    if (userInfo) {
      this.$store.commit('setUserData', JSON.parse(userInfo))
    }
    if(userdetInfo){
      this.$store.commit('setDetailData', JSON.parse(userdetInfo))
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },

  render: h => h(App)
}).$mount('#app')
