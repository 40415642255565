<template>
  <div>
    <div class="page-header clear-filter" filter-color="black">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg-home.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="photo-container">
          <img src="img/default-avatar.png" alt="" />
        </div>
        <h3 class="title">{{$store.state.userdetail.name }}</h3>
        <p class="category">Calon Karyawan</p>
        <div class="content">
          <div class="social-description">
            <h2>{{quizwaiting}}</h2>
            <p>Kuis Menunggu</p>
          </div>
          <div class="social-description">
            <h2>{{quizfinish}}</h2>
            <p>Kuis Selesai</p>
          </div>
          <!-- <div class="social-description">
            <h2>{{datadashboard.mypoint}}</h2>
            <p>Poin Anda </p>
          </div> -->
        </div>
      </div>
    </div>
    
    <div class="section">
      <div class="container">
        <div class="button-container">
          <router-link to="/profile" class="btn btn-danger btn-round btn-lg">Update Profil</router-link>
          <!-- <router-link to="/profile" class="btn btn-success btn-round btn-lg" v-bind:class="{'btn-danger blink':btneditprofile}">Update Profil</router-link> -->
        </div>
        <div class="body-table">
          <h3 class="title">Daftar Kuis</h3>
          <h5 class="description text-dark mb-0 blink">
            Pastikan anda telah memperbarui data profil anda, karena pihak <b class="text-warning weight-600">HRD</b> akan menghubungi anda
            melalui yang ada dalam profil ini.
          </h5>
          <table class="table table-responsive-sm table-striped">
            <thead class="thead-dark">
            <tr>
              <th class="text-center">No</th>
              <th>Judul Kuis</th>
              <th>Kategori</th>
              <th>Durasi Waktu</th>
              <!-- <th>Batas Waktu</th> -->
              <!-- <th>Nilai</th> -->
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, icounter) in datadb"
                    v-bind:key="item.id">
                    <td class="text-center">{{++icounter}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.lesson}}</td>
                    <td>{{item.duration}} Minutes</td>
                    <!-- <td>{{item.link}}</td> -->
                    <!-- <td class="text-center text-success font-weight-bold">{{item.employee_point}}</td> -->
                    <td v-if="item.employee_status =='1'"><span class="square-8 bg-info mg-r-5 rounded-circle"></span> Proccess Evaluations </td>
                    <td v-else-if="item.employee_status =='2'"><span class="square-8 bg-success mg-r-5 rounded-circle"></span> Final Score </td>
                    <td v-else><span class="square-8 bg-danger mg-r-5 rounded-circle"></span> No Submitted </td>
                    <td class="text-center" v-if="item.employee_status ==null ">
                        <router-link :to="{name:'prequiz', params:{slug:item.link}}" target="_blank" class="btn btn-sm btn-info m-0 start-quiz" ><i class="fa fa-file mr-1"></i>Start Quiz</router-link>
                    </td>
                    <td class="text-center" v-else-if="item.employee_status =='0'">
                        <router-link :to="{name:'quiz', params:{slug:item.link}}" target="_blank" class="btn btn-sm btn-warning m-0 start-quiz" ><i class="fa fa-file mr-1"></i>Continue</router-link>
                    </td>
                    <td class="text-center" v-else>-</td>
                </tr>
            </tbody>
          </table>
        </div>        
      </div>
    </div>
    
  </div>
</template>
<script>
import { Tabs, TabPane } from '@/components'
import Card from '../components/Cards/Card.vue'

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    Tabs,
    TabPane
  },
  data() {
      return {
          datadb: null,
          quizwaiting:0,
          quizfinish:0,
          datadashboard:[],
          btneditprofile:false,
      };
  },
  mounted() {
      this.getData();
      this.getDataDashboard();
  },
  methods: {
    getData(page) {
        this.$http({
            url: `/quiz`,
            method: "POST",
            data:{'id':'nndproject'},
        }).then(
            res => {
                this.datadb = res.data.data;
                this.quizwaiting = this.datadb.filter(item => (item.employee_status == 0 || item.employee_status ==null)).length
                this.quizfinish = this.datadb.filter(item => (item.employee_status != 0 && item.employee_status != null) ).length
            },
            () => {
                console.log('API Error')
            }
        );
    },
    getDataDashboard() {
        this.$http({
            url: `/employee-dashboard`,
            method: "GET",
        }).then(
            res => {
              this.datadashboard = res.data.data
            },
            () => {
                console.log('API Error')
            }
        );
    }
  },
}

</script>
<style scoped>

*{
    font-size: 100%;
}
.square-8 {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  }

.square-10 {
  display: inline-block;
  width: 10px;
  height: 10px; }

.rounded-circle {
  border-radius: 50% !important;
}

.body-table{
  margin-top: -1  0px !important;
}

.social-description>p,h1{
  font-weight: 500 !important;
}
.blink {
  animation: blinker 2s linear infinite;
}

.blink:hover{
  font-weight: 400;
  animation: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}



@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  *{
      font-size: 100% !important;
  }
}



</style>
